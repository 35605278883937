@import "styles/styles.scss";

.frame {
  background: url(/assets/images/interview-steps-sun.svg);
  height: 210px;
  width: 210px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $font-family-bold;
  font-size: 40px;
  color: $electric-cyan;
}
.frameMarginL {
  margin-bottom: 6%;
}
.frameMarginM {
  margin-bottom: 20%;
}
.frameMarginS {
  margin-bottom: 30%;
}

.image {
  width: 131px;
  height: 131px;
  margin-right: 2px;
}

.title {
  width: 339px;
  height: 76px;
  color: $white;
  font-family: $font-family-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
}

.list {
  margin-top: 3%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: $font-family;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  li::before {
    content: " ★ ";
    color: $electric-cyan;
  }
}

.buttonL {
  width: 30%;
}

.buttonM {
  width: 30%;
}

.buttonS {
  width: 70%;
}
