@import "styles/styles.scss";

.frame {
  background: url(/assets/images/interview-steps-sun.svg);
  height: 210px;
  width: 210px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $font-family-bold;
  font-size: 40px;
  color: $electric-cyan;
  margin-bottom: 6%;
}

.textContainer {
  text-align: center;
}

.title {
  width: 339px;
  height: 76px;
  color: $electric-cyan;
  font-family: $font-family-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
}
 
.text {
  font-family: $font-family;
  font-style: normal;
  font-weight: 700;
  color: white;
  text-align: center;
  margin: 3% 0 0 0;
}

.timeContainer {
  background-color: $aquagreen;
  border-radius: 5px;
  padding: 1%;
  margin: 3% 0 0 0;
}

.time {
  color: white;
  font-family: $font-family;
  font-style: normal;
  font-weight: 500;
}

.timeMarginL {
  margin: 2% 0 3% 0;
}
.timeMarginS {
  margin: 5% 0 10% 0;
}
.timeMarginM {
  margin: 2% 0 10% 0;
}
