@import "styles/styles.scss";

.section {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: url(/assets/images/interview-steps-background-stars.svg),
    $gradiant-cape-verde;
}

.backArrow {
  position: absolute;
  top: 2%;
  left: 2%;
}