@import "./variables";

// Mobile - typescale

@mixin display-xl($color) {
  font-family: $font-family;
  font-weight: bold;
  font-style: normal;
  color: $color;
  font-size: 36px;
  line-height: 52px;
}

@mixin display-l($color) {
  font-family: $font-family;
  font-weight: bold;
  font-style: normal;
  color: $color;
  font-size: 32px;
  line-height: 52px;
}

@mixin display-m($color) {
  font-family: $font-family;
  font-weight: 700;
  font-style: normal;
  color: $color;
  font-size: 28px;
  line-height: 40px;
}

@mixin display-s($color) {
  font-family: $font-family;
  font-weight: bold;
  font-style: normal;
  color: $color;
  font-size: 24px;
  line-height: 36px;
}

@mixin heading($color, $font-weight) {
  font-family: $font-family;
  font-weight: $font-weight;
  font-style: normal;
  color: $color;
  font-size: 20px;
  line-height: 28px;
}

@mixin heading-b($color, $font-weight) {
  font-family: $font-family-bold;
  font-weight: $font-weight;
  font-style: normal;
  color: $color;
  font-size: 20px;
  line-height: 28px;
}

@mixin subheading($color, $font-weight) {
  font-family: $font-family;
  font-weight: $font-weight;
  font-style: normal;
  color: $color;
  font-size: 13px;
  line-height: 24px;
}

@mixin body-1($color, $font-weight) {
  font-family: $font-family;
  font-weight: $font-weight;
  font-style: normal;
  color: $color;
  font-size: 16px;
  line-height: 24px;
}

@mixin body-2($color, $font-weight) {
  font-family: $font-family;
  font-weight: $font-weight;
  font-style: normal;
  color: $color;
  font-size: 14px;
  line-height: 24px;
}

@mixin caption($color, $font-weight) {
  font-family: $font-family;
  font-weight: $font-weight;
  font-style: normal;
  color: $color;
  font-size: 12px;
  line-height: 20px;
}

@mixin subcaption($color, $font-weight) {
  font-family: $font-family;
  font-weight: $font-weight;
  font-style: normal;
  color: $color;
  font-size: 10px;
  line-height: 16px;
}

// Desktop - typescale

@mixin display-xl-desktop($color) {
  font-family: $font-family;
  font-weight: bold;
  font-style: normal;
  color: $color;
  font-size: 36px;
  line-height: 52px;
}

@mixin display-l-desktop($color) {
  font-family: $font-family;
  font-weight: bold;
  font-style: normal;
  color: $color;
  font-size: 32px;
  line-height: 48px;
}

@mixin display-m-desktop($color) {
  font-family: $font-family;
  font-weight: bold;
  font-style: normal;
  color: $color;
  font-size: 28px;
  line-height: 40px;
}

@mixin display-s-desktop($color) {
  font-family: $font-family2;
  font-weight: bold;
  font-style: normal;
  color: $color;
  font-size: 36px;
  line-height: 28px;
}

@mixin heading-desktop($color, $font-weight) {
  font-family: $font-family;
  font-weight: $font-weight;
  font-style: normal;
  color: $color;
  font-size: 20px;
  line-height: 28px;
}

@mixin subheading-desktop($color, $font-weight) {
  font-family: $font-family;
  font-weight: $font-weight;
  font-style: normal;
  color: $color;
  font-size: 13px;
  line-height: 24px;
}

@mixin body-1-desktop($color, $font-weight) {
  font-family: $font-family;
  font-weight: $font-weight;
  font-style: normal;
  color: $color;
  font-size: 16px;
  line-height: 24px;
}

@mixin body-2-desktop($color, $font-weight) {
  font-family: $font-family;
  font-weight: $font-weight;
  font-style: normal;
  color: $color;
  font-size: 14px;
  line-height: 20px;
}

@mixin caption-desktop($color, $font-weight) {
  font-family: $font-family;
  font-weight: $font-weight;
  font-style: normal;
  color: $color;
  font-size: 12px;
  line-height: 20px;
}

@mixin subcaption-desktop($color, $font-weight) {
  font-family: $font-family;
  font-weight: $font-weight;
  font-style: normal;
  color: $color;
  font-size: 10px;
  line-height: 16px;
}

@mixin button-principal($font-family, $color, $font-weight) {
  font-family: $font-family;
  font-weight: $font-weight;
  font-style: normal;
  color: $color;
  font-size: 20px;
  line-height: 28px;
}

@mixin button-link($font-family, $color, $font-weight) {
  font-family: $font-family;
  font-weight: $font-weight;
  font-style: normal;
  color: $color;
  font-size: 13px;
  line-height: 24px;
}

@mixin input-option-text($font-family, $color, $font-weight) {
  font-family: $font-family;
  font-weight: $font-weight;
  font-style: normal;
  color: $color;
  font-size: 16px;
  line-height: 24px;
}

@mixin link-text-s($font-family, $color, $font-weight) {
  font-family: $font-family;
  font-weight: $font-weight;
  font-style: normal;
  color: $color;
  font-size: 16px;
  line-height: 24px;
}

@mixin link-text-m($font-family, $color, $font-weight) {
  font-family: $font-family;
  font-weight: $font-weight;
  font-style: normal;
  color: $color;
  font-size: 20px;
  line-height: 28px;
}

@mixin border-box{
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
}

// extends

%block__position--center--top {
  margin: 0 auto;
  display: block flex;
}