@import "styles/styles.scss";

.container {
  font-family: $font-family;
  margin-top: 64px;
  width: 100%;
  min-height: calc(100vh - 64px);
}

.backArrow {
  position: absolute;
  top: 2%;
  left: 2%;
}

.middle {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: calc(100vh - 64px);
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.18px;
  margin: 1% 0;
  margin-bottom: 24px;
}

.box {
  background: $pastel-cyan;
  border-radius: 16px;
  width: 30%;
  padding: 16px;
  margin: 16px 1%;
}

.boxM {
  width: 60%;
}

.boxS {
  width: 80%;
}

.jobDomain {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: #1d222a;
}

.jobLibelleCourt {
  font-size: 16px;
  margin-top: 12px;
  color: #1d222a;
}

.jobName {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  color: $cape-verde;
  margin-top: 4px;
}

.jobDescription {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  width: 30%;
  margin-bottom: 16px;
}

.jobDescriptionM {
  width: 60%;
}

.jobDescriptionS {
  width: 80%;
}

.skillSection {
  width: 30%;
  margin: 1% 0 5% 0;
}

.skillSectionM {
  width: 60%;
  margin: 2% 0 3% 0;
}

.skillSectionS {
  width: 80%;
  margin: 2% 0 5% 0;
}

.skillsTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 24px;
  text-transform: uppercase;
  color: $cape-verde;
}

.jobSkills {
  margin: 1% 0;
}

.jobSkillName {
  font-size: 14px;
  color: #ffffff;
  background: #00827f;
  border-radius: 5px;
  padding: 6px 8px;
  display: inline-block;
  margin: 16px 16px 0 0;
}

.footer {
  bottom: 0;
  left: 0;
  right: 0;
}
