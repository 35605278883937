@import "styles/styles.scss";

.containerInput {
  display: flex;
  align-items: center;
  gap: 6px;
  margin: 12px 0;
}

.input {
  font-size: 16px;
  font-weight: 400;
  word-wrap: break-word;
  word-break: break-word;
  resize: none;
  overflow: hidden;
  font-family: $font-family;

  height: 30px;
  width: 80%;
  border: 1px solid #e9ecef;
  border-radius: 12px;

  padding-top: 8px;
  padding-bottom: 0px;
  padding-left: 4px;
  padding-right: 4px;
  &:focus {
    border: 1px solid #00827f;
    outline: none;
  }
}

.icon {
  width: 25px;
  height: 25px;
}

.errorMaxLetterMessage {
  display: flex;
  justify-content: flex-end;
  width: 90%;
  font-size: 0.8rem;
  font-style: italic;
}
