@import "styles/styles";

.container {
  max-width: 800px;
  margin: 0 auto;
  @include input-option-text($font-family, $grey-600, 400);
}

.customInput {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding: 8px 10px;
  border: 1px solid $grey-400;
  border-radius: 6px;
  margin: 0 auto;
  margin-bottom: 1em;
  @include input-option-text($font-family, $grey-600, 400);
}

.customInput:focus {
  outline: none;
  color: $black;
  border: 1px solid $aquagreen;
  border-radius: 6px;
}

.customSelectbox {
  position: relative;
  display: inline-block;
  width: 100%;
}

.customSelectbox:after {
  content: " ";
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid $grey-600;
  position: absolute;
  right: 12%;
  top: 30%;
  transition: all 0.3s linear;
}
