@import "styles/styles";

.scrollTop {
  position: fixed; 
  width: 50px;
  height: 50px;
  bottom: 0px;
  right: 0px;
  // align-items: center;
  // height: 20px;
  justify-content: end;
  z-index: 1000;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  opacity: 1;
}

.scrollTop:hover{
  opacity: 1;
}
