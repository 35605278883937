@import "styles/styles.scss";

.section {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow-x: hidden;
  font-family: $font-family;
}

.arche {
  background: $gradiant-cape-verde;
  height: 100vh;
  width: 100%;
}

.backArrow {
  position: absolute;
  top: 2%;
  left: 2%;
}

.imgMail {
  position: absolute;
  top: 0;
  z-index: 5;
  transition: transform 1s, top 1s;
}

.imgMailM {
  width: 30%;
}

.imgMailS {
  width: 50%;
}

.middle {
  background: $white;
  width: 110%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 11%;
  border-radius: 50% 50% 0 0;
  min-height: 70vh;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 6% 0 3% 0;
}

.middleM {
  padding: 23% 0 0 0;
}

.middleS {
  padding: 30% 0 10% 0;
}

.title {
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.18px;
  color: #013332;
  font-size: 23px;
  width: 100%;
  text-align: center;
  margin-top: 7%;
}

.text {
  text-align: center;
  margin-top: 32px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  display: block;
  width: 92%;
  padding-left: 5%;
  color: #013332;
}

.form {
  width: 22%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.formM {
  width: 58%;
  padding: 13% 0 0 0;
}

.formS {
  width: 85%;
  display: flex;
  flex-direction: column;
}

.buttonContainer {
  width: 80%;
  text-align: center;
}

.input {
  width: 85%;
  border: 1px solid #e9ecef;
  height: 56px;
  display: flex;
  justify-content: center;
  border-radius: 12px;
  margin-top: 5%;
  padding: 0 15px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
}

.input:focus {
  border: 1px solid #00827f;
  outline: none;
}

.titleCalendar {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.18px;
  text-align: center;
}

.noDateButton {
  color: black;
  border-radius: 12px;
  background-color: white;
  border: 1px solid #e9ecef;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.noDateButton:hover,
.noDateButton:focus {
  color: white;
  background: #ea6625;
}

.errors {
  color: red;
  font-size: 12px;
  text-align: left;
  font-style: italic;
  margin-top: 10px;
  margin-top: 10px;
}

.mentions {
  color: #013332;
  font-size: 14px;
  width: 80%;
  margin-top: 10px;
}

@media only screen and (min-width: 1024px) and (max-width: 1280px) {
  .imgMail {
    width: 15%;
  }
  .form {
    width: 46%;
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1979px) {
  .form {
    width: 37%;
  }
  .middle {
    width: 100%;
  }
}
