@import "styles/styles.scss";

.generalInfosTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #f8f9fa;
  background-color: $cape-verde;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 2% 5%;
}

.interviewInfos {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.generalInfosBody {
  margin-block: 2%;
}

.interviewEmail{
  text-decoration: underline;
}