$animationSpeed: 40s;

// Animation
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}

// Styling
.slider {
  overflow: hidden;
  width: 960px;

  &::before,
  &::after {
    content: "";
    // height: 100px;
    position: absolute;
    // width: 200px;
    z-index: 2;
  }

  &::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  &::before {
    left: 0;
    top: 0;
  }

  .slideTrack {
    animation: scroll $animationSpeed linear infinite;
    display: flex;
    align-items: center;
    width: calc(250px * 14);
  }

  .slide {
    margin-right: 55px;
  }
}

@media (max-width: 768px) {
  .slider {
    width: 720px;
    .slide {
      margin-right: 35px;
    }
  }
}

@media (max-width: 576px) {
  .slider {
    width: 385px;
    .slide {
      margin-right: 35px;
    }
  }
}

@media (max-width: 375px) {
  .slider {
    width: 335px;
    .slide {
      margin-right: 35px;
    }
  }
}

@media (max-width: 320px) {
  .slider {
    width: 280px;
    .slide {
      margin-right: 35px;
    }
  }
}
