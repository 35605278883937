@import "styles/styles.scss";

.label {
  background: $aquagreen;
  color: $white;
  display: block;
  padding: 4px 8px;
  text-align: center;
  border-radius: 5px;
  font-weight: 700;
  font-size: 16px;
  margin: 16px auto;
  width: fit-content;
}

.choice {
  border: 1px solid $grey-200;
  border-radius: 12px;
  padding: 16px;
  width: 89%;
  font-weight: 700;
  text-align: center;
  margin-bottom: 16px;
  cursor: pointer;

  &:hover {
    border-color: $tiger;
  }

  &.selected {
    background: $tiger;
    color: $white;
    border-color: $grey-200;
  }
}

.choiceS {
  width: 91%;
}

.choiceM {
  width: 86%;
}

.multiInputListL {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.multiInputListS {
  margin: 0;
}
