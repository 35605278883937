@import "styles/styles.scss";

.section {
  overflow: hidden;
}

.reviewsScore {
  font-family: "ProximaNova";
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 89.6px */
  letter-spacing: 0;
}

@media (max-width: 576px) {
  .reviewsScore {
    font-size: 36px !important;
    line-height: 140%;
  }
}
