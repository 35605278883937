@import "styles/styles.scss";

.bulletPointImg {
  width: 12px;
  height: 12px;
}

.bulletPoint {
  display: flex;
  align-items: center;
  margin: 1% 0;
}

.activity {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #1d222a;
  margin: 0 1%;
}
