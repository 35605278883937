@import "styles/styles.scss";

.mainContainer {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  .label {
    background: $aquagreen;
    color: $white;
    display: inline-block;
    padding: 4px 8px;
    text-align: center;
    border-radius: 5px;
    font-weight: 700;
    font-size: 16px;
  }
  .inputContainer {
    margin-top: 3rem;
    width: 96%;
  }
  .inputContainerS{
    width: 99%;
  }
  .inputContainerM{
    width: 92%;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1280px) {
  .inputContainer {
    width: 50%;
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1979px) {
  .inputContainer {
    width: 50%;
  }
}