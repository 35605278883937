@import "styles/styles.scss";

$medium-space: 24px;
$small-space: 12px;

.container {
  font-family: $font-family;
  display: flex;
  // min-height: auto sized in JS
  * {
    box-sizing: border-box;
  }
}

.pageStepCount {
  font-size: 16px;
}

.centerContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.backArrow {
  margin-top: $small-space;
  margin-left: 2%;
}

.question {
  flex-grow: 1;
  max-width: 508px;
  max-height: 1000px;
  margin: auto;
  padding: $small-space;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.questionName {
  font-size: 24px;
  text-align: center;
  padding-top: $medium-space;
}

.toolTipBox {
  background-color: $pastel-cyan;
  border-radius: 12px;
  padding: $small-space;
  img {
    display: block;
    margin: auto;
    margin-bottom: $small-space;
  }
}

.toolTipTextContainer {
  font-size: 14px;
  line-height: 20px;
  color: $cape-verde;
}

.toolTipTextTitle {
  font-size: 14px;
  font-family: $font-family-bold;
  word-spacing: 1px;
}

.toolTipTextContent {
  margin-top: 2px;
  i {
    font-style: italic;
  }
}

@include tiny-screen-query {
  .toolTipBox {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    img {
      margin: 0;
    }
  }

  .toolTipTextContainer {
    padding: 2px $small-space;
  }
}
