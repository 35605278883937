@import "components/common/Carousels/Carousels.module.scss";

$borderRadius: 10px;

.owl-dots {
  display: none;
}

img.owl-icon {
  margin: -($iconWidthDefault/2) auto 0%;
}

.owl-item {
  background-color: $white;
  border-radius: $borderRadius;
  width: 180px;
}

div.owl-content {
  text-align: center;
  padding-bottom: 15px;
}

b.owl-titre {
  width: 100%;
  @include input-option-text($font-family-bold, $tiger, 700);
}

.owl-carousel .owl-item img {
  border-radius: $borderRadius;
}

.owl-stage-outer {
  margin: 5px auto 12px auto;
  //width: 80%;
}

.owl-stage {
  margin-top: 5px;
  margin-bottom: 12px;
}
