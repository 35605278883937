@import "styles/styles";

.videoPlayer {
  width: 876px;
  height: 547px;
}

.videoAnimation[animation="1"] {
  animation-name: videoAnimation;
  animation-duration: 2s;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: linear;
}

@keyframes videoAnimation {
  0% {
    width: 305.425px;
    height: 425.573px;
    border-radius: 150px;
    background-color: #ff6000;
    transform: rotate(-20.005deg);
  }
  10% {
    width: 305.425px;
    height: 425.573px;
    border-radius: 150px;
    background-color: #ff6000;
    transform: rotate(0);
  }
  30% {
    width: 305px;
    height: 547px;
    border-radius: 125px;
    background-color: #ff6000;
  }
  50% {
    width: 876px;
    height: 547px;
    border-radius: 20px;
    background-color: #ff6000;
  }
  100% {
    width: 876px;
    height: 547px;
    background-color: #ff6000;
    border-radius: 20px;
  }
}

@media (max-width: 768px) {
  .videoPlayer {
    width: 706px;
    height: 547px;
  }

  @keyframes videoAnimation {
    0% {
      width: 305.425px;
      height: 425.573px;
      border-radius: 150px;
      background-color: #ff6000;
      transform: rotate(-20.005deg);
    }
    10% {
      width: 305.425px;
      height: 425.573px;
      border-radius: 150px;
      background-color: #ff6000;
      transform: rotate(0);
    }
    30% {
      width: 305px;
      height: 547px;
      border-radius: 125px;
      background-color: #ff6000;
    }
    50% {
      width: 706px;
      height: 547px;
      border-radius: 20px;
      background-color: #ff6000;
    }
    100% {
      width: 706px;
      height: 547px;
      background-color: #ff6000;
      border-radius: 20px;
    }
  }
}

@media (max-width: 576px) {
  .videoPlayer {
    width: 334px;
    height: 210px;
  }
}
