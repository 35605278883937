$border-radius: 20px;
$box-shadow: -5px 5px 10px 0px rgba(0, 0, 0, 0.2);
$padding: 30px;

.allboxes {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 1fr 1fr 1fr;
  transition: all linear 1s;
  height: 488px;
  width: 100%;
}

.blue {
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  padding: $padding;
  background-color: #5794f7;
  grid-column: 3;
  grid-row: 2;
  display: flex;
  text-align: left;
  flex-direction: column;
  justify-content: space-between;
  border-style: none;
  width: 309px;
  height: 244px;
  .arrowIcon {
    transition: transform 0.3s ease-in-out;
    transform: rotate(0deg);
  }

  &:hover {
    cursor: pointer;
    .arrowIcon {
      transform: rotate(45deg);
    }
  }
}

.green {
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  padding: $padding;
  background-color: #00827f;
  grid-column: 2 / span 2;
  grid-row: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-style: none;
  width: 714px;
  height: 232px;
  .arrowIcon {
    transition: transform 0.3s ease-in-out;
    transform: rotate(0deg); // Initial rotation
  }

  &:hover {
    cursor: pointer;
    .arrowIcon {
      transform: rotate(45deg);
    }
  }
}

.orange {
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  padding: $padding;
  background-color: #ff6000;
  text-align: left;
  grid-column: 1;
  grid-row: 1 / span 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-style: none;
  width: 471px;
  height: 488px;
  .arrowIcon {
    transition: transform 0.3s ease-in-out;
    transform: rotate(0deg); // Initial rotation
  }

  &:hover {
    cursor: pointer;
    .arrowIcon {
      transform: rotate(45deg);
    }
  }
}

.brown {
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  padding: $padding;
  background-color: #352d2d;
  grid-column: 2;
  grid-row: 2;
  display: flex;
  text-align: left;
  flex-direction: column;
  justify-content: space-between;
  border-style: none;
  width: 392px;
  height: 244px;
  .arrowIcon {
    transition: transform 0.3s ease-in-out;
    transform: rotate(0deg); // Initial rotation
  }

  &:hover {
    cursor: pointer;
    .arrowIcon {
      transform: rotate(45deg);
    }
  }
}

@media only screen and (max-width: 1200px) {
  .blue,
  .green,
  .orange,
  .brown {
    width: 100%;
  }
}
