@import "styles/styles";

.videoCarousel {
  display: flex;
  position: relative;
}

.videoCarouselItem {
  scroll-snap-align: center;
  cursor: pointer;
  height: 587px;
  margin-right: 2%;
  margin-bottom: 1%;
}

.videoCarouselItem video {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.blankSpaceForMobile {
  display: block;
  width: 100px;
}

@media only screen and (min-width: 768px) {
  .blankSpaceForMobile {
    display: none;
  }
}
