@import "styles/styles";

.container {
  max-width: 800px;
  margin: 0 auto;
  textarea {
    resize: none;
  }
}

.customInput {
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding: 8px 10px;
  border: 1px solid $grey-400;
  border-radius: 6px;
  margin: 0 auto;
  margin-bottom: 1em;
  @include input-option-text($font-family, $grey-600, 400);
}

.customInput::placeholder {
  @include input-option-text($font-family, $grey-600, 400);
}

.customInput:focus {
  outline: none;
  color: $black;
  border: 1px solid $aquagreen;
  border-radius: 6px;
}
