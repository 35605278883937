@import "styles/styles.scss";

.toolTipBoxImg img {
  width: 40px;
  height: 40px;
}

.toolTipBox {
  background-color: #cbf1f5;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 5% 0;
}

.toolTipBoxImg {
  margin: 3%;
}

.toolTipTextContainer {
  font-size: 14px;
  line-height: 20px;
  color: #013332;
  width: calc(100% - 12px);
  margin: 2%;
}

.toolTipTextTitle {
  font-size: 14px;
  font-family: $font-family-bold;
  word-spacing: 1px;
}

.toolTipTextContent {
  margin-top: 2px;
}
