@import "styles/styles.scss";

.mentions {
  text-align: center;
  font-size: 14px;
  margin: 28px 0;
  font-family: "ProximaSoft";
  color: #013332;
}

calendly-inline-widget {
  overflow: hidden;
}
