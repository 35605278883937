@import "styles/styles.scss";

.section {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow-x: hidden;
  font-family: $font-family;
}

.arche {
  background: $gradiant-cape-verde;
  height: 100vh;
  width: 100%;
}

.backArrow {
  position: absolute;
  top: 2%;
  left: 2%;
}

.timer {
  position: absolute;
  top: 0;
  z-index: 5;
  display: flex;
  justify-content: center;
}

.middle {
  background: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 11%;
  padding: 7% 0 14% 0;
  border-radius: 50% 50% 0 0;
}

.middleM {
  padding: 23% 0 19% 0;
}

.middleS {
  top: 15%;
  width: 150%;
  padding: 30% 0 10% 0;
  justify-content: space-between;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.18px;
  color: #013332;
}

.text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  display: block;
}

.textRequired {
  margin-top: 10px;
  color: $grey-600;
}

.form {
  width: 15%;
  min-height: 100%;
  margin-top: 25px;
}

.formM {
  width: 35%;
}

.formS {
  width: 55%;
  display: flex;
  flex-direction: column;
}

.input {
  border: 1px solid #e9ecef;
  height: 56px;
  width: 93%;
  display: flex;
  justify-content: center;
  border-radius: 12px;
  padding: 0 15px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  margin-top: 10px;
}
.input::placeholder {
  color: $grey-600;
}

.inputS {
  width: 90%;
}
.inputS::placeholder {
  color: $grey-600;
}

.input:focus {
  border: 1px solid #00827f;
  outline: none;
}

.calendar {
  width: 480px;
  padding: 0% 0 5% 0;
}

.calendarS {
  width: 320px;
  padding: 0% 0 5% 0;
}

.titleCalendar {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.18px;
  text-align: center;
}

.noDateButton {
  color: black;
  border-radius: 12px;
  background-color: white;
  border: 1px solid #e9ecef;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.noDateButton:hover,
.noDateButton:focus {
  color: white;
  background: #ea6625;
}

.errors {
  color: red;
  font-size: 13px;
  text-align: right;
  font-style: italic;
}

@media only screen and (min-width: 1024px) and (max-width: 1280px) {
  .timer img {
    width: 60%;
  }
  .middle {
    padding: 6% 0 5% 0;
  }
  .form {
    width: 30%;
    min-height: 100%;
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1980px) {
  .timer img {
    width: 80%;
  }
  .form {
    width: 20%;
    min-height: 100%;
  }
}
