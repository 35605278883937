@import "styles/styles.scss";

.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: $font-family;
  color: white;
  background-color: $aquagreen;
  min-height: 100vh;
}

.backArrow {
  position: absolute;
  top: 2%;
  left: 2%;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: space-around;
  height: 100vh;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.18px;
}

.advice {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-top: 5%;
  width: 60%;
}

.topSection {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.bottomSectionL {
  width: 100%;
}

.bottomSectionS {
  width: 90%;
}
