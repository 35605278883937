@import "styles/styles.scss";

.bulletPoint {
  margin: 8px 0;
  font-size: 18px;
  b {
    font-family: "ProximaNovaBold";
    color: #00827f;
  }
}
