@import "styles/styles.scss";

.section {
  display: flex;
  height: 100vh;
  justify-content: space-evenly;
  flex-direction: column;
  background: $gradiant-cape-verde;
  padding: 0 20px;
}

.middle {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  font-family: $font-family;
  font-style: normal;
  text-align: center;
  font-weight: 700;
  color: white;
}
