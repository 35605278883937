.checkbox {
  display: none;
}

.button {
  height: 3rem;
  width: 3rem;
  position: fixed;
  right: 4vw;
  z-index: 2000;
  cursor: pointer;
}

.background {
  width: 100%;
  background-color: white;
}

.checkbox:checked ~ .background {
  position: absolute;
  height: 100vh;
  width: 100vw;
  margin-top: 916px;
  margin-left: -21px;
}

.nav {
  position: absolute;
  margin-top: 100px;
  z-index: 1500;
  margin-left: 16px;
  height: calc(100% - 100px);
}

.checkbox:checked ~ .nav {
  opacity: 1;
  width: 100%;
  right: -15px;
}

.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.icon,
.icon::before,
.icon::after {
  width: 2rem;
  height: 2px;
  background-color: black;
  display: inline-block;
}

.icon::before,
.icon::after {
  content: "";
  position: absolute;
  left: 0;
  transition: all 0.2s;
}

.icon::before {
  top: -0.8rem;
}
.icon::after {
  top: 0.8rem;
}

.checkbox:checked + .button .icon {
  background-color: transparent;
}

.checkbox:checked + .button .icon::before {
  top: 0;
  transform: rotate(135deg);
}
.checkbox:checked + .button .icon::after {
  top: 0;
  transform: rotate(-135deg);
}

@media (min-width: 768px) {
  .checkbox:checked ~ .background {
    margin-left: -39px;
  }
}
