.sectionProfileNoExampleBlock {
  display: block;
  font-style: italic;
  font-size: 14px;
  line-height: 24px;
  color: #1d222a;
}

.sectionProfileExampleBlock {
  display: block;
  font-size: 14px;
  line-height: 24px;
  color: #1d222a;
}

.profileSkill {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.skillRateTag {
  line-height: 28px;
  padding: 0 1%;
  border-radius: 6px;
  text-align: center;
  font-size: small;
}

.skillRateTag0 {
  background-color: #ffecf9;
}

.skillRateTag1 {
  background-color: #fbe2d5;
}

.skillRateTag2 {
  background-color: #d8ffd2;
}

@media (max-width: 600px) {
  .profileSkill {
    display: block;
  }
}
