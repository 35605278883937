@import "styles/styles.scss";

.container {
  font-family: $font-family;
  width: 100%;
  min-height: calc(100vh - 64px);
  background-image: url("../../../assets/images/InterviewSummaryBackground.webp");
  background-size: cover;
  padding-bottom: 120px;
}

.navigationContainer {
  padding: 4% 10%;
  display: flex;
  align-items: center;
}

.backArrow {
  width: 10%;
}

.logoContainer {
  width: 90%;
  text-align: center;
}

.logoImage {
  height: 42px;
  width: 222px;
}

.head {
  color: $white;
  padding: 4% 10%;
}

.interviewContainer {
  margin: 0 10%;
  background-color: #ffffff;
  border-radius: 12px;
}

.title {
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: 0.18px;
}

.subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #f8f9fa;
}

.separator {
  width: 100%;
  height: 0px;
  margin: 2% 0 2% 0;
  border-bottom: 1px solid #ccd6d6;
}

.sectionTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #f8f9fa;
  background-color: $cape-verde;
  padding: 1% 5%;
}

.sectionSubtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #868e96;
}

.sectionJobDetailTitle {
  display: block;
  font-weight: bolder;
  font-size: 16px;
  line-height: 24px;
  color: $aquagreen;
  font-family: "ProximaSoftBold";
  margin-top: 12px;
}

.sectionContentBlock {
  display: block;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #1d222a;
}

.sectionContent {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #1d222a;
  text-align: right;
  padding-left: 10%;
}

.containerTitle {
  width: 100%;
  background-color: $cape-verde;
  color: $white;
  padding: 2% 0;
}

.containerInfosProfileS {
  width: 90%;
  margin: auto;
  padding: 2% 0 2% 0;
}

.containerInfosProfileM {
  width: 90%;
  margin: auto;
  padding: 2% 0 2% 0;
}

.containerInfosProfileL {
  width: 90%;
  margin: auto;
  padding: 2% 0 2% 0;
}

.containerInfos {
  width: 90%;
  margin: auto;
  padding: 2% 0 2% 0;
}

.containerAlert {
  margin: 10%;
}

.emailSendAlert {
  position: fixed;
  top: 5%;
  right: 10%;
  background-color: #00827f !important;
  border-radius: 15px;
}

.containerSendEmail {
  padding: 2% 0 2% 0;
  background: #ffffff;
  box-shadow: 0px -2px 16px #bfbbbb;
  margin-top: 12px;
}

.stickySendEmailBtn {
  position: fixed;
  bottom: 0;
  width: 100%;
}

@media (max-width: 1200px) {
  .emailSendAlert {
    top: 5%;
  }
}

@media (max-width: 900px) {
  .emailSendAlert {
    top: 12%;
  }
}

@media (max-width: 600px) {
  .emailSendAlert {
    width: 75%;
    top: 8%;
  }

  .logoImage {
    height: 30px;
    width: 150px;
  }
  .navigationContainer {
    padding: 4% 20px;
  }

  .head {
    padding: 4% 20px;
  }

  .interviewContainer {
    margin: 0 20px;
  }
}
