/*------------------------------------------
>>> Struture SCSS
-------------------------------------------
1. -- CarouselScroll
------------------------------------------*/
@import "styles/styles";

$borderRadiusDefault: 20px;
$iconWidthDefault: 40%;
$cardWidthDefault: 220px;
$cardPaddingDefault: 30px 15px 20px 15px;
$paddingWidthDefault: 30px;

%block__imageContainer {
  display: flex;
  position: relative;
  width: 100%;
  border-radius: 10px;
  top: -20px;
  height: 100px;
  justify-content: center;
  align-items: center;
  line-height: 100px;
}

.carousel {
  font-family: $font-family;
  margin: 0 auto;
  padding: 0 20px 75px;
  scroll-behavior: smooth;
  width: 100%;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.carousel b {
  font-weight: bold;
}

.carousel::-webkit-scrollbar {
  display: none;
}

.cardsContainer {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  min-width: initial;
  margin: 0px auto;
  overflow: auto;
}

.cardOuter {
  align-self: flex-start;
  margin-left: 0;
  margin-top: 40;
}
.cardImage {
  width: 100%;
  border-radius: $borderRadiusDefault;
}

.cardStyleWthoutImg {
  box-shadow: $z3;
  padding: $cardPaddingDefault;
  border-radius: $borderRadiusDefault;
}

.hidden {
  display: none;
}

.suggestionsContent {
  padding-bottom: 10px;
  min-width: 250px;
  box-shadow: $z3;
  border-radius: $borderRadiusDefault;
}

.suggestionsImageContainerBgMandarine {
  @extend %block__imageContainer;
  background-color: $pastel-mandarine;
}

.suggestionsImageContainerBgGreen {
  @extend %block__imageContainer;
  background-color: $pastel-green;
}

.suggestionsImageContainerBgCyan {
  @extend %block__imageContainer;
  background-color: $pastel-cyan;
}

.suggestionsImageContainerBgPink {
  @extend %block__imageContainer;
  background-color: $pastel-pink;
}

.suggestionsImage {
  max-width: 70%;
  min-width: 40%;
  max-height: 90%;
}

.suggestionsTextContainer {
  width: 80%;
  margin: 0 auto;
  padding-bottom: 10px;
  min-height: calc(min(40vh, 230px));
}

.suggestionsTextTitle {
  @include heading-b($cape-verde, bold);
  text-align: center;
}

.suggestionsTextDescription {
  @include body-1($grey-900, normal);
  text-align: center;
  line-height: 20px;
  font-size: calc(min(2.7vh, 20px));
}

:export {
  whiteColor: $white;
  blackColor: $black;
  borderRadius: $borderRadiusDefault;
  iconWidth: $iconWidthDefault;
  cardWidth: $cardWidthDefault;
  cardPadding: $cardPaddingDefault;
  paddingWidth: $paddingWidthDefault;
}
