@import "styles/styles.scss";

.flexBoxContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.roleSectionTitle {
  font-size: 62px;
  font-family: "DMSerifText";
  width: 90%;
}

.roleSectionSubTitle {
  font-size: 18px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-top: 40px;
  margin-bottom: 32px;
  width: 80%;
  font-family: $font-family;
}

.roleSectionLink {
  margin-left: 30px;
  color: black !important;
  text-decoration: none;
  text-transform: none !important;
}

.herosImage {
  height: 382px;
  width: auto;
}

@media (max-width: 1200px) {
  .roleSectionTitle {
    font-size: 42px;
  }

  .roleSectionLink {
    margin-left: 0;
  }
}

@media (max-width: 900px) {
  .herosImage {
    height: 280px;
    width: auto;
  }
}

@media (max-width: 576px) {
  .flexBoxContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .herosImage {
    height: 240px;
    width: auto;
  }

  .roleSectionTitle {
    font-size: 26px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 140% !important;
  }

  .roleSectionSubTitle {
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 140% !important;
    margin-bottom: 16px;
    width: 100%;
  }

  .right {
    width: 70%;
    padding: 10%;
    display: flex;
    justify-content: center;
  }
}
