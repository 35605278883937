.emailInputDone button {
  border: none !important;
}

.emailInput,
.emailInputError,
.emailInputDone {
  border-radius: 10px !important;
  background-color: transparent;
  height: 40px;
  width: 100%;
  max-width: 250px;
  font-size: 12px !important;
}

.emailInput,
.emailSendButton {
  border: #a69c95 !important;
}

.emailSendButton {
  margin-right: -8px !important;
  border: 2px solid #a69c95 !important;
}

.acceptTermsLabel,
.buttonTerms {
  color: #868e96 !important;
  font-size: 12px !important;
}

.buttonTerms {
  display: inline-flex;
  padding: 0 !important;
  text-decoration: underline !important;
}

.warningTermsNotChecked,
.errorEmailFormat,
.emailInputError input {
  font-size: 12px !important;
  color: #c10000 !important;
}

.emailInputError fieldset,
.emailSendButtonError {
  border: 2px solid #c10000 !important;
}

.emailSendButtonError {
  margin-right: -8px !important;
}
