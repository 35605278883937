/* Proxima Soft Regular */
@font-face {
  font-family: "ProximaSoft";
  font-style: normal;
  font-weight: 400;
  src: url("ProximaSoftRegular.eot"); /* IE9 Compat Modes */
  src: local("ProximaSoftRegular"), local("Proxima-Soft-Regular"),
    url("ProximaSoftRegular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("ProximaSoftRegular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("ProximaSoftRegular.woff") format("woff"); /* Modern Browsers */
}
/* Proxima Soft Bold */
@font-face {
  font-family: "ProximaSoftBold";
  font-style: normal;
  font-weight: 700;
  src: url("ProximaSoftBold.eot"); /* IE9 Compat Modes */
  src: local("ProximaSoftBold"), local("Proxima-Soft-Bold"),
    url("ProximaSoftBold.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("ProximaSoftBold.woff2") format("woff2"),
    /* Super Modern Browsers */ url("ProximaSoftBold.woff") format("woff"); /* Modern Browsers */
}
/* DMSerifText-Regular */
@font-face {
  font-family: "DMSerifText";
  font-style: normal;
  src: url('./DMSerifText-Regular.ttf') format('truetype');
}
/* DMSerifText-Italic */
@font-face {
  font-family: "DMSerifTextItalic";
  font-style: normal;
  src: url('./DMSerifText-Italic.ttf') format('truetype');
}
/* Proxima-Nova-Regular */
@font-face {
  font-family: "ProximaNova";
  font-style: normal;
  src: url('./Proxima-Nova-Regular.ttf') format('truetype');
}
/* Proxima-Nova-Regular */
@font-face {
  font-family: "ProximaNovaMedium";
  font-style: normal;
  src: url('./Proxima-Nova-Medium.ttf') format('truetype');
}
/* Proxima-Nova-Regular-Italic */
@font-face {
  font-family: "ProximaNovaItalic";
  font-style: normal;
  src: url('./Proxima-Nova-Regular-Italic.ttf') format('truetype');
}
/* Proxima-Nova-Bold */
@font-face {
  font-family: "ProximaNovaBold";
  font-style: normal;
  src: url('./Proxima-Nova-Bold.ttf') format('truetype');
}
/* Proxima-Nova-Bold-Italic */
@font-face {
  font-family: "ProximaNovaBoldItalic";
  font-style: normal;
  src: url('./Proxima-Nova-Bold-Italic.ttf') format('truetype');
}
/* Proxima-Nova-SemiBold */
@font-face {
  font-family: "ProximaNovaSemiBold";
  font-style: normal;
  src: url('./Proxima-Nova-Semibold.ttf') format('truetype');
}
/* Proxima-Nova-SemiBold-Italic */
@font-face {
  font-family: "ProximaNovaSemiBoldItalic";
  font-style: normal;
  src: url('./Proxima-Nova-Semibold-Italic.ttf') format('truetype');
}