.react-calendar {
  width: 350px;
  max-width: 100%;
  background: white;
  font-family: "ProximaSoft";
  line-height: 1.125em;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar__navigation__label {
  text-transform: uppercase;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 24px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 0px;
  background: none;
}
.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: transparent;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: capitalize;
  color: #ea6625;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
  font-weight: 700;
  font-size: 12px;
  line-height: 120%;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}
.react-calendar__month-view__days__day--weekend {
  color: black;
}
.react-calendar__month-view__days__day--weekend:disabled {
  color: #b8b8b8;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  &:enabled:hover {
    border-radius: 8px;
    color: white;
    background: #ea6625;
  }
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:focus {
  color: #ea6625;
  border-bottom: 1px solid #ea6625;
}
/* .react-calendar__tile--now {
  color: #ea6625;
  border-bottom: 1px solid #ea6625;
} */
/* .react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #76baff;
} */

.react-calendar__tile--active {
  color: #ea6625;
  border-bottom: 1px solid #ea6625 !important;
}

.react-calendar__tile--hasActive {
  color: #ea6625;
  border-bottom: 1px solid #ea6625;
}
.react-calendar__tile--hasActive:enabled:hover {
  border-radius: 8px;
  color: white;
  background: #ea6625;
}

.react-calendar__tile--hasActive:enabled:focus {
  color: #ea6625;
  border-bottom: 1px solid #ea6625;
}

.react-calendar__tile--active:enabled:focus {
  color: #ea6625;
  border-bottom: 1px solid #ea6625;
}
.react-calendar__tile--active:enabled:hover {
  border-radius: 8px;
  color: white;
  background: #ea6625;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__navigation__prev-button,
.react-calendar__navigation__next-button {
  font-size: 30px;
  width: 40px;
}