// Primary colors

$cape-verde: #013332;
$cape-verde-light: #00827F;
$gradiant-cape-verde: linear-gradient(
  110.77deg,
  #013332 -11.08%,
  #0d131b 117.87%
);
$gradient-dark-verde: linear-gradient(
  110.77deg,
  #091c22 -11.08%,
  #0d131b 117.87%
);
$tiger: #ea6625;

// Complementary colors

$mandarine: #ffa65c;
$aquagreen: #00827f;
$aquagreen-light: #80c0bf;
$fountain: #52b4c9;
$electric-cyan: #00e0ea;
$electric-pink: #ee4aba;
$pastel-mandarine: #fbe2d5;
$pastel-green: #ccd6d6;
$pastel-cyan: #cbf1f5;
$pastel-pink: #ffecf9;

$white: #ffffff;
$black: #000000;

// shadows

$z1: 0px 1px 4px rgba(34, 40, 47, 0.1);
$z2: 0px 2px 16px rgba(34, 40, 47, 0.08);
$z3: 0px 4px 12px rgba(34, 40, 47, 0.1);
$z4: 0px 0px 8px rgba(0, 0, 0, 0.2);
$z5: 0px 4px 18px rgba(34, 40, 47, 0.2);

// Grey scale

$grey-0: #f8f9fa;
$grey-100: #f1f3f5;
$grey-200: #e9ecef;
$grey-300: #dee2e6;
$grey-400: #ced4da;
$grey-500: #adb5bd;
$grey-600: #868e96;
$grey-700: #585f66;
$grey-800: #1d222a;
$grey-900: #0d131b;

//font-family

$font-family: "ProximaSoft";
$font-family-bold: "ProximaSoftBold";

:export {
  shadowZ1: $z1;
  shadowZ2: $z2;
  shadowZ3: $z3;
}

$desktop-max: 1668px;
$desktop-min: 880px;

// Img Sizes

$img-radius-size-solution-card: 40px; // same value in component imgRadiusSize

// color uses

$light-text-color-main: $white;
$light-text-color-secondary: $grey-100;

// add more variables
