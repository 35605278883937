@import "styles/styles.scss";

.section {
  margin: 160px 0;
}

.title {
  display: flex;
  justify-content: center;
  margin-bottom: 56px;
}

.starIcon {
  width: 85.236px;
  height: 108px;
  border-radius: 20px;
  background: var(--ff-6000, rgba(255, 96, 0, 0.08));
  display: flex;
  justify-content: center;
  align-items: center;
}

.starText {
  border-right: 1px solid #e8e8e8;
  width: 84%;
  padding-right: 38px;
  .subtitle {
    margin-top: 32px;
  }
  .text {
    margin-top: 32px;
  }
}

@media (max-width: 576px) {
  .section {
    margin: 112px 20px;
    .title {
      margin-bottom: 48px;
    }
  }
  .star {
    display: flex;
    margin-bottom: 30px;
  }
  .starIcon {
    width: 53px;
    height: 67px;
  }
  .starIcon svg {
    width: 22px;
    height: 22px;
  }
  .starText {
    border: none;
    padding-right: 0;
    margin-left: 12px;
    .subtitle {
      margin-top: 0px;
    }
    .text {
      margin-top: 8px;
    }
  }
}
