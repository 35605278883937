/****************************************************************
*  container:                'react-autosuggest-job__container',
*  containerOpen:            'react-autosuggest-job__container--open',
*  input:                    'react-autosuggest-job__input',
*  inputOpen:                'react-autosuggest-job__input--open',
*  inputFocused:             'react-autosuggest-job__input--focused',
*  suggestionsContainer:     'react-autosuggest-job__suggestions-container',
*  suggestionsContainerOpen: 'react-autosuggest-job__suggestions-container--open',
*  suggestionsList:          'react-autosuggest-job__suggestions-list',
*  suggestion:               'react-autosuggest-job__suggestion',
*  suggestionFirst:          'react-autosuggest-job__suggestion--first',
*  suggestionHighlighted:    'react-autosuggest-job__suggestion--highlighted',
*  sectionContainer:         'react-autosuggest-job__section-container',
*  sectionContainerFirst:    'react-autosuggest-job__section-container--first',
*  sectionTitle:             'react-autosuggest-job__section-title'
*****************************************************************/
@import "styles/styles";

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.react-autosuggest-job__container {
  position: relative;
  margin-bottom: 1rem;
}

.react-autosuggest-job__input {
  display: flex;
  height: 56px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 15px;
  border: 1px solid $grey-200;
  border-radius: 12px;
  margin-top: 5%;
  color: black !important;
  z-index: 11;
  position: relative;
  @include input-option-text($font-family, $grey-600, 400);
}

.react-autosuggest-job__input--focused {
  outline: none;
  border: 1px solid $aquagreen;
}

.react-autosuggest-job__input--open {
  border-bottom-left-radius: 6;
  border-bottom-right-radius: 6;
}

.react-autosuggest-job__input::placeholder {
  color: $grey-600;
}

.react-autosuggest-job__suggestions-container {
  display: none;
}

.react-autosuggest-job__suggestions-container em {
  @include input-option-text($font-family-bold, $grey-900, 400);
}

.react-autosuggest-job__suggestions-container--open {
  display: block;
  box-sizing: border-box;
  border-bottom: 1px solid black;
  background-color: #fff;
  z-index: 10;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 200px;
  position: relative;
  bottom: 6px;
  
  @include input-option-text($font-family, $grey-900, 400);
  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  &::-webkit-scrollbar:vertical {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $grey-500;
    border: 2px solid #ffffff;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;  
    background-color: #ffffff; 
  }
  &::-o-scrollbar {
    -o-appearance: none;
  }
  &::-o-scrollbar:vertical {
    width: 6px;
  }
  &::-o-scrollbar-thumb {
    background-color: $grey-500;
    border: 2px solid #ffffff;
  }
  &::-o-scrollbar-track {
    border-radius: 10px;  
    background-color: #ffffff; 
  }
  &::-moz-scrollbar {
    -moz-appearance: none;
  }
  &::-moz-scrollbar:vertical {
    width: 6px;
  }
  &::-moz-scrollbar-thumb {
    background-color: $grey-500;
    border: 2px solid #ffffff;
  }
  &::-moz-scrollbar-track {
    border-radius: 10px;  
    background-color: #ffffff; 
  }
}

.react-autosuggest-job__section-title {
  padding: 10px 20px;
  font-weight: 600;
}

.react-autosuggest-job__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest-job__suggestion {
  cursor: pointer;
  padding: 10px 20px;
  border-top: 1px solid $grey-200;
}

.react-autosuggest-job__suggestion--first {
  border-top: 1px solid $grey-200;
}

.react-autosuggest-job__suggestion--highlighted {
  background-color: $grey-300;
}

.errors {
  color: red;
  font-size: 13px;
  text-align: right;
  font-style: italic;
}
