.gridContainer {
  max-width: 1200px;
  margin: auto;
}

.navLinksTitle {
  line-height: 40px !important;
  font-family: "ProximaNovaBold" !important;
}

.navLinkButton {
  display: block !important;
  font-size: 16px;
  padding: 0 !important;
  line-height: 32px !important;
}

.sectionTitle {
  line-height: 40px !important;
  font-family: "ProximaNovaBold" !important;
  display: block;
}

.socialMediaLink {
  padding-left: 0;
  min-width: 30px !important;
  width: 30px;
}

.divider {
  width: 100%;
  padding-top: 30px;
  margin-bottom: 30px !important;
}

.supportedBy {
  line-height: 40px !important;
  font-family: "ProximaNovaBold" !important;
}

.googleLogo {
  vertical-align: middle;
  margin-left: 10px;
  width: 100px;
  height: 22px;
}

.terms {
  text-align: right;
}

@media (max-width: 900px) {
  .terms {
    text-align: left;
  }

  .terms a {
    padding-left: 0;
  }
}

@media (max-width: 500px) {
  .googleLogo,
  .terms a {
    margin: 0;
    display: block;
  }
}

@media (max-width: 374px) {
  .diagnosticButton {
    font-size: 12px;
  }
}
