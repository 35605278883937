@import "styles/styles.scss";

.ContentContainer {
  h1 {
    font-family: "DMSerifText";
    font-size: 62px;
    color: black;
    margin-top: 10px;
    font-weight: 400;
    line-height: 100%;
    text-align: center;
  }

  h2 {
    @include heading-b($cape-verde, 700);
    margin-bottom: 1em;
  }

  b {
    @include body-1($grey-700, 700);
  }

  a {
    color: $aquagreen;
  }

  span {
    display: block;
    @include body-1($grey-700, 400);
    margin-bottom: 1em;
  }

  li {
    list-style: inside;
  }
}
