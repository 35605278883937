.buttonIcon {
  background-color: transparent;
  border: none;
  border-color: transparent;
  border-radius: 50%;
  transform: scale(0.96);
  cursor: pointer;
  // &:hover {
  //   border: 1px solid;
  //   filter: blur(0.1px);
  //   transition: all 1s;
  //   transform: scale(1);
  //   border-color: rgb(211, 202, 202);
  //   background-color: rgb(243, 237, 237);
  // }
  &:active {
    transition: all 1s;
    opacity: 0.5;
    background-color: rgb(211, 202, 202);
  }
}

.buttonIcon svg {
  color: red !important;
}