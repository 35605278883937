.proposalPicture {
  width: 100%;
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .proposalPictureBox {
    width: 100% !important;
  }
  .proposalPicture {
    height: 543px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .proposalPictureBox {
    width: 55% !important;
  }
}
