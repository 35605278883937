@import "styles/styles";

.buttonPrincipal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: static;
  text-align: center;
  margin: 10px auto;
  border-radius: 40px;
  background: $tiger;
  border: none;
  height: 40px;
  @include button-principal($font-family, $white, 600);
}

.buttonPrincipal:disabled {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 10px auto;
  background: $grey-200;
  border: none;
  @include button-principal($font-family, $grey-500, 600);
}

.buttonPrincipal:hover {
  cursor: pointer;
}

.buttonPrincipal:disabled:hover {
  cursor: auto;
}

.buttonSuggestion {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: static;
  text-align: center;
  margin: 10px auto;
  border-radius: 40px;
  background: $aquagreen;
  border: none;
  height: 40px;
  @include input-option-text($font-family, $white, 600);
}

.buttonSuggestion:disabled {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 10px auto;
  background: $grey-200;
  border: none;
  @include input-option-text($font-family, $grey-500, 600);
}

.buttonSuggestion:hover {
  cursor: pointer;
}

.buttonSuggestion:disabled:hover {
  cursor: auto;
}
